$pro_width_100: 100%;
.ship_text {
    font-family: 'Brandon Grotesque';
	img {
    	padding-right: 5px;
	}
}
#product {
    width: 70%;
}
.radio {
    float: left;
    width: $pro_width_100;
    border: 1px solid #ccc;
    padding: 12px 15px;
    border-radius: 10px;
    line-height: 21px;
	label {
		float: left;
		width: $pro_width_100;
		color:#b85c72;
		font-family: 'Brandon Grotesque';
		font-weight: 600;
		font-size:14px;
	}
	.rleft {
		float: right;
		font-weight: 400;
	}
}
.add_cart-box {
    float: left;
    width: $pro_width_100;
    margin-top: 15px;
	input#input-quantity {
		height: 45px;
		text-align: center;
		background: rgba(255,255,255,1);
	}
}
h3.add-heading {
    font-size: 16px;
    font-family: 'Brandon Grotesque';
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 6px;
}
.single_page_right h2 {
    font-size: 18px;
    font-family: 'Brandon Grotesque';
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 2px;
    letter-spacing: 1px;
}
.cst_product_checkbox .checkbox {
    float: left;
    margin: 0 15px 0 0;
	width:85px;
	label {
		padding-left: 0;
		width: $pro_width_100;
		input[type="checkbox"] {
			margin-left: 0;
		}
	}
}
.img_outercat img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
}
.margin-top-20 { margin-top:20px;}

/*--------------------*/
.margin-top-10 { margin-top:10px;}
.product_innerouter {
    margin-top: 20px;
}
.head_subouter h2 a {
    float: right;
    color: #B85C72 !important;
    font-family: "SF Pro Text";
    font-size: 16px;
    letter-spacing: -0.38px;
    line-height: 20px;
    text-align: right;
    font-weight: normal;
}
.head_subouter h2 a::after {
    content: "\f105";
    font-family: 'fontawesome';
    padding: 0;
}
.head_subouter h2 a:hover {
	color: #39b54a;
}
.head_subouter h2 {
    float: left;
    margin: 0;
    padding: 0 5px;
    letter-spacing: 1px;
    width: 100%;
    color: #000000;
    font-family: "SF Pro Text";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.27px;
    line-height: 21px;
}
ul.newcatleftul li a {
	color: rgba(0,0,0,0.9);
    font-family:
    Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    text-transform: uppercase;
}
ul.newcatleftul li a:hover {
    color: #383838;
}
ul.newcatleftul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

ul.newcatleftul li {
    display: block;
    margin: 0;
    padding-left: 20px;
}

.new_cat_left h5 a {
	opacity: 0.5;
    color: rgba(0,0,0,0.9);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
}

.releted_outer_single .slick-prev, .releted_outer_single .slick-next, .relted_product_sliderInf .slick-prev, .relted_product_sliderInf .slick-next {
    text-align: center;
}
.releted_outer_single .slick-next::before, .relted_product_sliderInf .slick-next::before {
    color: #FFF;
    font-weight: 300;
    margin: auto;
    display: block;
    padding-left: 5px;
}
.releted_outer_single .slick-prev, .relted_product_sliderInf .slick-prev  { z-index:9;}
.releted_outer_single .slick-prev::before, .relted_product_sliderInf .slick-prev::before {
    color: #FFF;
    font-weight: 300;
    margin: auto;
    display: block;
    padding-left: 0px;
}

.relted_product_sliderInf .slick-next {
    right: -15px;
}
.relted_product_sliderInf .slick-prev {
    left: -15px;
}
.product-grid .product-thumb .image + div {
    overflow: hidden;
}
select::-ms-expand {
    display: none;
}
/*-------Rating-------*/
.custom_rating span {
    font-size: 2.2rem;
    line-height: normal;
    font-weight: 900;
    color: #000000;
}
.custom_rating span a {
    text-decoration: none;
}
.custom_rating span.vivino_text {
    font-size: 16px;
    color: #891826;
    font-weight: 800;
}
.slick-next::before {
    content: url('../../image/arrow-right.png') !important;
}
.slick-prev::before {
    content: url('../../image/arrow-left.png') !important;
}


// product sldier
.relted_product_slider {

    .slick-prev {
        left: -18px;
    }

    .slick-next {
        right: -13px;
    }
}
