// Responsive Variables
$break-1025: 1025px;
$break-1024: 1024px;
$break-960: 960px;
$break-840: 840px;
$break-768: 768px;
$break-767: 767px;
$break-667: 667px;
$break-480: 480px;
$break-375: 375px;
$break-320: 320px;
$width-100: 100%;
$mv-font-size-16: 16px;
$mv-font-size-15: 15px;
$mv-font-size-14: 13px;
$footer-top-imgpath: url(../../image/footer_mobile_banner.png);
@media screen and (min-width: 48em) {
.cart-preview-container {
    position: relative;
    float: right;
}
}

@media (min-width: 1200px){
.prodect-outer .container {
    width: 1299px;
}
}


@media (min-width: $break-1025) {
// .wapper_inner .container {
//     max-width: 970px;
// }
.wapper_inner .product_main_outer .product-grid {
    width: 20%;
}
}
@media (min-width: 1200px){
#content .col-lg-2:nth-child(6n+1), #content .col-lg-2:nth-child(6n+1), #content .col-lg-3:nth-child(4n+1), #content .col-lg-4:nth-child(3n+1), #content .col-lg-6:nth-child(2n+1) {
    clear: inherit;
}}
@media (max-width: $break-1024) {
.cart-icon {
	text-align: right;
}
.menu ul li a {
    padding: 16px 4px;
    font-size: 15px;
}
.header-outer .container, .shoping-outer .container, .content-outer .container, .hot_items .container {
	width: $width-100;
}
.hot_items {
	.hot-item-text {
		padding: 40px 15px 12px;
	}
	.product:last-child {
		margin: 25px 0 0 6px;
	}
	.product {
		margin: 25px 14px 0;
		width: 22%;
	}
	.product:last-child {
		margin: 25px 0 0 0;
	}
}
.main-footer {
	.social-icon ul li {
		width: 30px;
		height: 30px;
		font-size: $mv-font-size-16;
		a {
			padding: 7px 0;
		}
	}
	.social-icon ul li img {
		max-width: 100%;
	}
}
.img_outercat {
    position: relative;
    .option_contant {
        position: absolute;
        top: 30%;
        right: 9%;
        bottom: 50%;
    }
}
}

@media (max-width: $break-960) {
.hot_items {
	.product {
		margin: 25px 14px 0;
	}
}



.taste-outer-maun {
	.container{
		width: $width-100;
	}
	.tast-text-item a {
		width: 20%;
	}
}
.main-footer .container {
	width: $width-100;
}
}

@media (max-width: $break-840) {

}
@media (min-width: $break-480) and (max-width: $break-1024) {
// .hot_items .product-layout:nth-child(4) {
//     padding-left: 0;
// }
// .hot_items .product-layout:nth-child(3) {
//     padding-right: 0;
// }
}
@media (min-width: $break-768) and (max-width: $break-1024) {
.hot_items .product-layout, .product_main_outer .product-grid {
	width: 33.33%;
}
.container {
	width: $width-100;
}
.owl-carousel .owl-item .item img {
    margin: 0 auto;
    max-width: 160%;
}
}
@media (min-width: $break-768) {
.modal-dialog {
    width: 800px;
    margin: 30px auto;
}
.congratulationpupbox .modal-dialog {
    width: 460px;
    margin: 30px auto;
}
}
@media (max-width: $break-768) {
div#modal-congratulation {
    padding-right: 0 !important;
}
.main-footer .information h5 {
    font-size: 16px;}
		.hot_items .hot-item-text:after {display:none;}
.cst_product_checkbox .checkbox label.labelbg:after {padding:12px 0;}
.cst_product_checkbox.check_related .form-group .checkbox {
    margin-right: 10%;
    width: 90%;
}
.cst_product_checkbox.check_related .form-group {width:25%;}
.menu {
	padding: 6px 0 0;
	margin: 0;
	ul li {
		display: inherit;
		a {
			padding: 10px 8px;
		}
	}
}
.navbar-brand > img {
	height: 100%;
	padding: 0;
}
.cart-icon {
	text-align: center;
	padding: 13px 0 10px;
}
.content-outer {
	.alcohol-text {
		padding: 0 0 40px;
		.alcohol-text h1 {
			font-size: 19px;
			padding: 10px 0 0;
			line-height: 30px;
			p {
				padding: 0 0 14px;
				line-height: 30px;
				font-size: 28px;
			}
		}
	}
}
.hot_items {
	.product {
		margin: 25px -11px 0 0;
		width: 34%;
		padding: 15px;
	}
	.hot-item-text {
		border-bottom: 1px solid #dcddde;
		padding: 40px 12px 12px;
		 h2 {
			font-size: 20px;
		}
		a {
			font-size: $mv-font-size-16;
		}
	}
}
.taste-outer {
	background-position: center;
	.tast-text-item h3 {
		font-size: 80px;
		padding: 0 0 0 100px;
	}
	.tast-text-item h2 {
		font-size: 30px;
		padding: 20px 20px 0;
	}
}
.main-footer {
	.social-icon {
		text-align: left;
		ul {
			margin: 0;
			padding: 0;
		}
	}
	.information h2 {
		font-size: $mv-font-size-16;
	}
	.information ul li a {
        font-size: 16px;
		line-height: 20px;
        margin-bottom: 20px;
	}
	.social-icon ul li {
		width: 30px;
		height: 30px;
		font-size: $mv-font-size-16;
		a {
			padding: 7px 0;
			img {
				width:100%;
			}
		}
	}
	.copyright-text {
		padding: 0px 0;
	}
}
.example-8 .navbar-brand {
	padding: 0px;
	transform: translateX(-50%);
	left: 50%;
	position: absolute;
	img {
		height: 100%;
		width: auto;
		padding: 7px 14px;
	}
}
}


@media (min-width: 768px) and (max-width: 1023px) {
    ul.newcatleftul li {
        display: block;
        text-align: center;
        width: 48%;
        background: #ffffff;
        border: 1px solid #b85c72;
        border-radius: 3px;
        float: left;
        margin: 1%;
    	padding-left:0;
    }
    ul.newcatleftul li a { display:block; padding: 5px; }
    ul.newcatleftul {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: $break-767) {
.navbar-default {
    text-align: left;
}

.owl-item .alcohol-text {
    padding-top: 0;
}

ul.newcatleftul li {
    display: block;
    text-align: center;
    width: 48%;
    background: #ffffff;
    border: 1px solid #b85c72;
    border-radius: 3px;
    float: left;
    margin: 1%;
	padding-left:0;
}
ul.newcatleftul li a { display:block; padding: 5px; }
ul.newcatleftul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}
.mobile_clear_fix { clear:both;}
.desktop_screen {display:none;}
.mobile_screen {display:block;}
.cart-preview-container {
    position: fixed;
    top: 0;
    height: calc(100% - 0px);
    width: 100%;
    overflow: hidden;
    z-index: 3;
}
.product-thumb .button-group button {
    visibility: hidden;
}
.product-thumb .button-group button::after {
    content: "+";
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #b85c72;
    color: #b85c72;
    line-height: 27px;
    font-size: 28px;
    right: 0;
    border-radius: 50%;
    text-align: center;
    visibility: visible;
	padding-left: 2px;
}
#content {
    min-height: auto;
}
.product_main_outer .product-grid .product-thumb .button-group {
    top: 15px;
    left: auto;
    background-color: transparent;
    width: 30px;
    height: 30px;
    bottom: auto;
    opacity: 1;
    right: 15px;
    float: none;
    display: block;
    transform: translate(0, 0);
}
.product_main_outer .product-grid .product-thumb:hover .button-group {
    opacity: 1;
}
.checkoutleftsection {border:none;}
.col-sm-2.mobilecart {
    position: absolute;
    right: 0;
}
.navbar-default .navbar-toggle {
    margin-right: 21%;
    margin-top: 13px;
}
.cart-icon #cart .dropdown-menu { min-width:300px;}

.owl-item {
	.item {
		height: 100%;
		img {
			object-fit: fill !important;
   			max-width: 150%;
		}
	}
}
.menu ul li {
	display: inherit;
	a {
		padding: 10px 0;
		font-size: 16px;
	}
}
.alcohol-text h1 {
    font-size: 18px;
}
.alcohol-text p { font-size:16px;}
.header-outer .container, .shoping-outer .container, .content-outer .container, .hot_items .container {
	width: 100%;
}

.navbar-brand > img {
	height: $width-100;
	padding: 0;
}
.cart-icon {
	text-align: center;
	padding: 0 0 10px;
	margin-top: 20px;
}
.menu {
	padding: 10px 0 0;
	border-bottom: 1px solid #ccc;
	margin: 0 0 10px;
}
.content-outer {
	.alcohol-text {
		padding: 0 0 40px;
		 h1 {
			font-size: 20px;
			padding: 10px 0 0;
			line-height: 30px;
		}
		p {
			padding: 0 0 14px;
			line-height: 30px;
			font-size: 28px;
		}
	}
}
.hot_items {
	.product {
		margin: 25px 10px 0 0;
		width: $width-100;
		padding: 15px;
	}
	.hot-item-text {
		border-bottom: 1px solid #dcddde;
		padding: 20px 12px 12px;
		 h2 {
			font-size: 16px;
		}
		a {
			font-size: $mv-font-size-16;
		}
	}
}
.hot_items .hot-item-text:after { display:none;}
.taste-outer {
	.tast-text-item {
		h3 {
			font-size: 80px;
			padding: 0;
			text-align: center;
		}
		 h2 {
			font-size: 30px;
			padding: 20px 20px 0;
			text-align: center;
		}

	}
}
.main-footer {
	.social-icon {
        margin-top: 20px;
		text-align: center;

		ul {
			margin: 0;
			padding: 0;
		}
	}
	.copyright-text {
		padding: 15px 0;
        margin-top: 0;
	}
}
.hot_items .product-layout, .product_main_outer .product-grid {
	width: 33.33%;
}
#product {
	width: $width-100;
}
.menu {
	border-bottom:none;
}
.navbar-default .navbar-collapse {
	border-color:#FFF;
}
.product_main_outer .product-grid .product-thumb {
	.caption h4 {
		height: 20px;
		overflow: hidden;
	}
	.button-group button {
        width: 30px;
		height: 30px;
        padding: 0;

        span {
            font-size: 0;
        }
	}
}
.hodgson_trading {
    float: right;
}
#product {
    width: 100%;
}
.custombutton {
    overflow: hidden;
}
.checkoutpagecontainer .KU_accordion {
    border-right: 0px solid #eee;
    padding-right: 0px;
	.paymentaddress {
		padding: 15px;
	}
}
.order_review_outer .table-responsive { padding:15px;}
.order_review_outer .checkoutright h2 { padding-left:0;}
.checkoutright ul { padding-left:0;}
.order_review_outer {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}
.checkout_review_outer {
    padding-right: 0;
    margin-bottom: 15px;
}
.checkoutpagecontainer {
    margin-top: 20px;
}
#content_payment_address .termstext.pull-left {
    width: 100%;
}
.main-footer .social-icon .hodgson_trading {
    margin-top: -30px;
}

.order_summery_r .totalsummyu { padding-left:0;}
div#slideshow0 {
    padding-top: 17%;
}
}

@media (max-width: 568px) {
    ul.newcatleftul li {
        display: block;
        text-align: center;
        width: 100%;
        background: #ffffff;
        border: 1px solid #b85c72;
        border-radius: 3px;
        float: left;
        margin: 1%;
    	padding-left:0;
    }

    ul.newcatleftul li a {
        font-size: 12px;
        line-height: 20px;
    }
}


@media (max-width: $break-667) {
table.choose-datePC span.timedisable {
    margin-left: 3px;
    font-size: 10px;
    margin-right: 3px;
}
.delivery-date table.PC-bodyA tr.rowtigger.rowdisable td {
    color: #c0bdc4;
    font-size: 10px;
}
.delivery-date table tr td {
    font-size: 10px;
}
}

@media (max-width: $break-480) {

.owl-item {
	height: 250px;
	.item {
		height: 100%;

		img {
			object-fit: fill !important;
			max-width: 332%;
			margin-left: -45% !important;
		}
	}
}
ul.breadcrumb {
    margin-top: 130px;
}
.hot_items .product-layout, .product_main_outer .product-grid {
	width: 50%;
}
.hot_items .product-layout:nth-child(odd) {
	padding-left: 0;
}
.hot_items .product-layout:nth-child(even) {
	padding-right: 0;
}
.product_main_outer .product-grid .product-thumb .caption h4 a, .product_main_outer .product-grid .product-thumb .caption p, .product_main_outer .product-grid .product-thumb .caption p.price, .product_main_outer .product-grid .product-thumb .button-group button, .shoping-outer .glass-img h2, .shoping-outer .glass-img p {
	font-size: $mv-font-size-14;
}
.prod_desc_right {
	h2 {
		font-size: $mv-font-size-16;
	}
	button {
		font-size: $mv-font-size-14;
		padding: 10px 30px;
		width: 110px;
	}
}
.prod_desc_left {
	 h3 {
		font-size: 18px;
	}
	span {
		font-size: $mv-font-size-15;
	}
	 strong {
		font-size: $mv-font-size-16;
	}
}
.main-footer .social-icon ul li {
	width: 30px;
	height: 30px;
	font-size: $mv-font-size-16;
	margin: 0 2px;
	img {
		max-width: $width-100;
	}
}
.glass-img img {
	max-width: $width-100;
}
.shoping-outer {
	padding:0;
}
div#logo img {
	width: 150px;
}
.cart-icon {
	margin-top: 15px;
}
.main-footer {
	.information {
		ul li a {
			font-size: $mv-font-size-15;
			line-height: 20px;
		}
		h5 {
			font-size: $mv-font-size-15;
		}
	}
	.social-icon h5 {
		font-size: $mv-font-size-14;
	}
}
.taste-outer .tast-text-item {
    bottom: 5px;
}
.mobile_screen {min-height:160px;}
// .product_main_outer .product-grid .product-thumb .button-group { width:115px; position: static;}
.navbar-default .navbar-toggle {
    margin-right: 32%;
    margin-top: 8px;
}
div#slideshow0 {
    padding-top: 126px;
}
.product_main_outer .product-grid .product-thumb .caption { padding:10px 0;}
.head_subouter h2 {
	font-size:1.2em;
}
.congratulationpupbox .congratulationimg img, .congratulationpupbox .congratulationimg-logo img {
    width: 54%;
}
.congratulationpupbox h2.modal-title {
    font-size: 24px;
    margin-top: 10px;
}
.congratulationpupbox p.congratulation_sub {
    font-size: 18px;
}
}

@media (max-width: $break-375) {
// .product_main_outer .product-grid .product-thumb .image img {
// 	height: 150px;
// }
.main-footer .social-icon ul li {
	width: 26px;
	height: 26px;
	margin: 0 1px;
}
.cart-icon #cart {
    width: 86px;
	padding: 8px 5px;
	}
	.product_main_outer .product-grid .product-thumb .caption h4 a, .product_main_outer .product-grid .product-thumb .caption p, .product_main_outer .product-grid .product-thumb .caption p.price, .product_main_outer .product-grid .product-thumb .button-group button, .shoping-outer .glass-img h2, .shoping-outer .glass-img p {
	font-size: 12px;
}
}
@media (max-width: $break-320) {
.main-footer .social-icon .hodgson_trading {
	font-size: 10px;
}
}


@media (min-width: 992px) and (max-width: 1199px) {
    .navbar-header {
        width: 125px;
    }

    .navbar-default .navbar-collapse {
        width: calc(100% - 125px);
    }

    .menu {
        width: calc(100% - 190px);
    }

    .menu ul li {
        padding: 20px 0;
        margin: 0 26px;
    }

    .menu ul li a {
        padding: 0;
        font-size: 12px;
    }

    .search-box {
        max-width: 190px;
    }

    .search-box #search {
        height: 36px;

        .input-group-btn {
            width: 40px;
            font-size: 16px;
        }

        .btn-lg {
            font-size: 16px;
        }

        .input-lg {
            border-radius: 5px;
            padding: 10px 15px 10px 40px;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .menu ul li:hover {

        .sub-megamenu {
            top: 60px;
        }
    }

    .sub-wrap {
        max-width: 585px;
    }

    .sub-megamenu {
        padding: 20px 0;

        ul li {
            margin: 0;
            padding: 0;

            a {
                line-height: 30px;
                font-size: 10px;
            }
        }
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    // header
    .header-outer {

        .row > [class^='col-']:first-child {
            padding-right: 5px;
        }
    }

    .navbar-header {
        width: 110px;
    }

    .navbar-default .navbar-collapse {
        width: calc(100% - 110px);
        padding: 0 10px;
    }

    .menu {
        padding: 0;
        width: calc(100% - 170px);
    }

    .menu ul li {
        padding: 18px 0;
        margin: 0 20px;
        display: inline-block;
    }

    .menu ul li:first-child {
        margin-left: 0;
    }

    .menu ul li:last-child {
        margin-right: 0;
    }

    .menu ul li a {
        padding: 0;
        font-size: 10px;
    }

    .search-box {
        max-width: 170px;
    }

    .search-box #search {
        height: 30px;

        .input-group-btn {
            width: 36px;
            font-size: 16px;
        }

        .btn-lg {
            font-size: 16px;
        }

        .input-lg {
            border-radius: 5px;
            padding: 8px 12px 8px 36px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .menu ul li:hover {

        .sub-megamenu {
            top: 56px;
        }
    }

    .sub-wrap {
        max-width: 530px;
    }

    .sub-megamenu {
        padding: 15px 0;

        ul li {
            margin: 0;
            padding: 0;
            display: block;

            a {
                line-height: 26px;
                font-size: 10px;
            }
        }
    }
}



@media (max-width: 767px) {
    .menu-bars {
        z-index: 1;
        top: 10px;
        font-size: 24px;
        right: 15px;
        position: absolute;
        display: inline-block;
    }

    // header
    .header-outer {

        .row > [class^='col-']:first-child {
            width: 100%;
        }
    }

    .navbar-header {
        width: 140px;
    }

    .navbar-default .navbar-toggle {
        display: none;
    }

    .navbar-default .navbar-collapse {
        z-index: 4;
        padding: 0;
        top: 0;
        right: -110%;
        width: 100%;
        position: fixed;
        background-color: #2d2a2a;
        border: none;
        min-height: 100vh;
        height: 100vh;
        overflow-y: auto;
        transition: right 0.5s ease-in-out;
    }

    .navbar-default .navbar-collapse.active {
        right: 0;
    }

    .menu {
        margin: 15px 0;
        padding: 0;
        padding-right: 70px;
        width: 100%;
    }

    .menu ul {
        text-align: left;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .menu ul li {
        padding: 0;
        margin: 0;
        width: 100%;
        display: block;
        border-bottom: 1px solid #fff;
    }

    .menu ul li a {
        padding: 15px;
        color: #fff;
        font-size: 13px;
        display: inline-block;
    }

    .navbar-default .navbar-nav > li > a {
        color: #fff;
    }

    .closeIcon {
        z-index: 2;
        height: 26px;
        width: 26px;
        top: 10px;
        right: 15px;
        position: absolute;
        display: inline-block;

        &::before,
        &::after {
            content: '';
            top: 50%;
            height: 2px;
            width: 100%;
            background-color: #fff;
            transform-origin: center;
            position: absolute;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    .dropDown {
        z-index: 2;
        top: 12px;
        right: 15px;
        width: 20px;
        height: 20px;
        position: absolute;
        display: inline-block;

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            position: absolute;
            border-style: solid;
            border-color: #fff;
            border-width: 0 0 2px 2px;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .search-box {
        max-width: 100%;
    }

    .search-box #search {
        height: 34px;
        margin-bottom: 15px;

        .input-group-btn {
            width: 36px;
            font-size: 16px;
        }

        .btn-lg {
            font-size: 16px;
            border: none;
        }

        .input-lg {
            border-radius: 5px;
            padding: 8px 12px 8px 36px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .menu ul li:hover {

        .sub-megamenu {
            top: 0;
        }
    }

    .sub-wrap {
        max-width: 100%;
    }

    .sub-megamenu {
        z-index: 1;
        padding: 10px 0;
        background-color: #222121;
        position: relative;
        display: none;

        ul {
            margin: 0;
            width: 100%;
        }

        ul li {
            margin: 0;
            padding: 0;
            display: block;
            border: none;

            a {
                padding: 8px 15px;
                line-height: 26px;
            }
        }
    }

    .mobilecart {
        width: auto;
        padding: 0;
        z-index: 1;
        top: 15px;
        position: absolute;
    }

    .col-sm-2.mobilecart {
        right: 50px;
    }

    .cart-icon {
        margin: 0;
        padding: 0;
    }
}


@media (max-width: 375px) {
    .cart-icon #cart {
        width: auto;
        padding: 0;
    }
}
