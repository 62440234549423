//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      content: "-";
      padding: 0 10px;
      color: #716f6f;
      font-size: 21px;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
