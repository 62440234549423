$font-family: 'Brandon Grotesque';
$bold-weight: bold;
$no-margin : 0;
$no-padding : 0;
$color: #404041;
$prod-font-size-22: 22px;
%prod_desc_right_btn_hover {
	background: #da7d93;
	color: #fff;
	text-decoration: none;
	outline: none;
}
%ku_accordion {
	color: #929497;
	font-size:13.5px;
	font-family: 'Brandon Grotesque';
}
%orderfirstname_lastname {
	width: 48%;
	float: left;
}
%brandon_family_weight {
	font-family: $font-family;
	font-weight: $bold-weight;
}
body { font-family:$font-family;}
.prod_desc_left {
	line-height: 30px;
	padding: 0;
	h3 {
		font-size: $prod-font-size-22;
		margin: $no-margin;
		@extend %brandon_family_weight;
		background: none;
		color: #000;
		text-align: left;
	}
	span {
		display: block;
		font-size: $prod-font-size-22;
		font-family: $font-family;
	}
	strong {
		display: block;
		font-size: $prod-font-size-22;
		@extend %brandon_family_weight;
	}
}

.prod_desc_right {
	float: right;
	text-align: right;
	padding: 0 5px;
	 h2 {
		font-size: 24px;
		margin: $no-margin;
		@extend %brandon_family_weight;
		background: none;
		color: #000;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-bottom: 10px;
	}
	button {
		@extend %brandon_family_weight;
		color: #fff;
		font-size: 20px;
		letter-spacing: 2px;
		background: #43af49;
		text-transform: uppercase;
		border: none;
		border-radius: 30px;
		padding: 15px 30px;
		transition: all 0.5s ease-in-out 0s;
		width: 180px;
		 i {
			display: none;
		}
	}
	button:hover {
		@extend %prod_desc_right_btn_hover;
	}
	button:focus {
		@extend %prod_desc_right_btn_hover;
	}
}
.product_main_outer .product-grid .product-thumb:hover{
	z-index: 1;
}
.product-grid .product-thumb .image {
    float: none;
    margin: 0px -10px;
}
.product_main_outer {
	float: left;
	width: 100%;

	h3 {
		margin: 0 0 20px;
		float: left;
		text-transform: uppercase;
		font-size: 24px;
		font-weight: normal;
		font-family: $font-family;
		letter-spacing: 1px;
		width: 100%;
		border-bottom: 1px solid #dcddde;
		padding-bottom: 12px;
	}
	.product-grid .product-thumb {
		transition: .25s ease;
		-ms-transition: .25s ease;
		-moz-transition: .25s ease;
		-webkit-transition: .25s ease;
		padding: 10px;
		position: relative;
		border: 1px solid rgba(0,0,0,0.1);
		background-color: #FFFFFF;
		padding-bottom: 14px;
		.overLay {
			z-index: 0;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: rgba($color-maroon, 0.2);
			transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
		}

		.caption {
			z-index: 1;
			min-height: 82px;
			float: left;
			width: 100%;
			padding: 10px;
			line-height: 24px;
			position: relative;
			 h4 {
				margin: $no-margin;
   				overflow: hidden;
					height: auto;
					padding-bottom: 5px;
				 a {
					color: #000000;
					font-family: "SF Pro Text";
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -0.7px;
					line-height: 16px;
					white-space: nowrap;
				}
				a:hover, a:focus  {
					text-decoration: none;
					color: #B85C72;
				}
			}
			p {
				text-align: left;
				padding: $no-padding;
				margin: $no-margin;
				font-family: 'SF Pro Text';
				font-size: 12px;
				letter-spacing: -0.2px;
				line-height: 14px;
				color: #000000;
				opacity: 0.5;
			}

			p.price {
				text-align: left;
				font-size: 14px;
				font-family: 'SF Pro Text';
				position: absolute;
				line-height: 16px;
				letter-spacing: -0.6px;
				color: #000000;
				opacity: 0.5;
				padding-top: 6px;

				span.wgclass {
					text-transform: lowercase;
				}
			}

			p.prices {
				text-align: right;
			}

			.special {
			    position: absolute;
			    bottom: 0;
			}
		}

		.button-group {
			z-index: 9;
			top: 50%;
			left: 50%;
	        border: none;
			overflow: unset;
			position: absolute;
			width: 150px;
			height: 44px;
			box-sizing: border-box;
			opacity: 0;
			transform: translate(-50%, -50%);
			background-color: rgba(184,92,114,0.15);
			transition: opacity 0.2s ease-in-out;

			.custombutton {
				width: 100%;
				display: inline-block;
			}

			button {
				width: 100%;
				height: 100%;
				float: left;
				display: inline-block;
				text-align: center;
				background: #B85C72;
				margin: 0;
				padding: 4px 0;
				text-transform: uppercase;
				color: #FFFFFF;
				border: none;
				transition: all 0.5s ease-in-out 0s;
				border-radius: 4px;
				letter-spacing: 2.51px;
				font-size: 12px;
				font-family: 'Montserrat';
				font-weight: 600;
			}

			button:hover, button:focus {
				@extend %prod_desc_right_btn_hover;
			}
		}
	}
	.product-grid {
		/*clear: none !important;*/
		padding: 0 5px;
	}
}



.product_main_outer .product-grid .product-thumb:hover {

	.overLay {
		z-index: 1;
		opacity: 1;
	}

	.button-group {
		opacity: 1;
	}
}
.cst_product_checkbox .checkbox label a:focus {
    outline: none;
}
.order_summery_r tr:last-child td {
    font-size: 21px;
    font-weight: 600;
}
.product_main_outer.releted_outer_single {
    border: none;
    -webkit-box-shadow: 0 10px 50px 0 rgba(0,0,0,.1);
    box-shadow: 0 10px 50px 0 rgba(0,0,0,.1);
    margin-bottom: 1em;
    padding: 10px 24px 20px;
}
.releted_outer_single h3 {
	margin: 0 0 20px;
	float: left;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
 	@extend %brandon_family_weight;
	// letter-spacing: 1px;
		width: 100%;
	//border-top: 2px solid #dcddde;
	padding-bottom: 0;
	padding-top: 9px;
	border-bottom: none;
	font-family: "SF Pro Text";
	color: #000000;
	text-transform: capitalize;
	letter-spacing: -0.27px;
	line-height: 21px;




	a {
		color:$bdr-color-maroon;
		  font-size: 16px;
			line-height: 20px;
			float: right;
		}
}
.single_product_view {
	margin-bottom: 20px;
}
.product_single_imgblock {
    width: 86%;
	float:left;
	margin-bottom:30px;
}
.single_page_left ul.thumbnails li a {
	padding: $no-padding;
	border: none;
	display: block;
	float: left;
	img {
		float: left;
		border: 1px solid #d0d2d3;
	}
}
.product_single_imgblock .slick-initialized .slick-slide { margin-bottom:0;}
.single_page_right #product .radio, .single_page_right #product .add_cart-box {
	max-width: 350px;
}
.single_page_right {float:left}

.pop_desc {
    float: left;
    width: 100%;
	h4 {
		opacity: 0.6;
		color: #000000;
		font-family: "SF Pro Text";
		font-size: 14px;
		letter-spacing: -0.33px;
		line-height: 20px;
	}
}

.checkout-popup-box .pop_desc p, .checkout-popup-box .pop_desc p span { line-height: 16px !important;}
.checkout-popup-box .pop_desc {
    float: left;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    max-height: 34px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.checkout-popup-box .pop_desc h4 {       line-height: 34px;
    margin: 0;}
.pop_desc p, .pop_desc p span {
    font-family: "Brandon Grotesque" !important;
    font-weight: normal;
    font-size: 14px !important;
}
.single_page_right #product .ship_text {
	float: left;
	width: 100%;
	margin-top: 15px;
	color: #000;
}
.single_page_right .pro_desc p {
	@extend %brandon_family_weight;
	font-size: 16px;
	color: $color;
}
.single_page_right .add_cart-box #button-cart {
 	@extend %brandon_family_weight;
	text-transform: uppercase;
	box-shadow: none;
}
.single_page_right #product .radio span.sleft {
	padding-left: 30px;

}
.single_page_right #product .radio label {
	padding-left: 0;
}
.single_page_right #product .radio [type="radio"]:checked, .single_page_right #product .radio [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.single_page_right #product .radio [type="radio"]:checked + span.rbtn_style, .single_page_right #product .radio [type="radio"]:not(:checked) + span.rbtn_style {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: block;
	color: #666;
}
.single_page_right #product .radio [type="radio"]:checked + span.rbtn_style:before, .single_page_right #product .radio [type="radio"]:not(:checked) + span.rbtn_style:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #b85c72;
	border-radius: 100%;
	background: #fff;
}
.single_page_right #product .radio [type="radio"]:checked + span.rbtn_style:after, .single_page_right #product .radio [type="radio"]:not(:checked) + span.rbtn_style:after {
	content: '';
	width: 12px;
	height: 12px;
	background: #b85c72;
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.single_page_right #product .radio [type="radio"]:not(:checked) + span.rbtn_style:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.single_page_right #product .radio [type="radio"]:checked + span.rbtn_style:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
.single_page_left .cst_product_checkbox, .single_page_left .cst_product_checkbox .form-group {
	float: left;
	width: 100%;
}

.single_page_left .cst_product_checkbox .checkbox label .img-thumbnail {
	padding: $no-padding;
}
.single_page_left .cst_product_checkbox .checkbox {width:95%; margin-right:10%;}
.cst_product_checkbox.related_data .form-group.related_pro:last-child .checkbox {
    margin-right: 0;
}
.single_page_right input#input-quantity {
	background: #f1f1f2 !important;
	font-size: 16px;
	font-weight: $bold-weight;
}
ul.breadcrumb {
	background: none;
	border: none;
	text-transform: uppercase;
    padding: 8px 0;
	margin-top:102px;
}

ul.breadcrumb li a, .breadcrumb > li {
    color: rgba(0,0,0,0.9);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;


}
ul.breadcrumb li a:hover, ul.breadcrumb li a:focus {
	text-decoration: none;
	color: #da7d93;
}
.breadcrumb > li:after {
	border: none;
	width: auto;
	height: auto;
}
.breadcrumb > li {
	padding: $no-padding;

}
span.price-new {
	color: #e71a4c;
}
.cst_product_checkbox .checkbox label {
	position: relative
}
.cst_product_checkbox .checkbox label input[type="checkbox"] {
	visibility: hidden;
}
.cst_product_checkbox .checkbox label.labelbg:after {
	color: #fff;
	content: "";
	visibility: visible;
	display: block;
	z-index: 9999;
	position: absolute;
	width: 100%;
	background: rgba(69,175,73,0.5);
	height: 100%;
	margin: auto;
	font-size: 30px;
	font-family: 'FontAwesome';
	padding: 20px 25px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align:center;
}
.cart-icon #cart p.text-right a {
	color: #fff;
}
.cart-icon #cart p.text-right a:hover {
	color: #B85C72;
}
.checkoutpagecontainer {
	padding-top: 180px;
	width: 100%;
	max-width: 970px;
}
.order_review_outer .checkoutright h2 {
	margin-top: 0;
	color: #404041;
	font-size: 14px;
	@extend %brandon_family_weight;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding-bottom: 15px;
	padding-left: 25px;
	padding-right: 20px;
	margin-bottom: 0
}
.order_review_outer { margin-bottom:30px;}
.checkoutright ul {
	list-style: none;
	padding: $no-padding;
	margin: $no-margin;
	padding-left: 20px;
	border-top: 1px solid #ecedee;
	padding-bottom: 50px;
	margin-bottom: 5px;
	li {
		padding: 20px 0 0;
		.product_l {
			float: left;
			width: 17%;
			img {
				width: 42px;
				height: 42px;
				border-radius: 100%;
				border: 1px solid #ecedee;
				display: inline-block;
				float: left;
			}
		}
		.product_mid {
			float: left;
			@extend %brandon_family_weight;
			font-size: 14.5px;
			color: $color;
			 small {
				font-family: $font-family;
				font-size: 12.5px;
				font-weight: 500;
				display: block;
			}
			span {
				display: block;
				font-weight: normal;
			}
		}
		.product_ri {
			float: right;
			color: #231f20;
			border: 1px solid #231f20;
			padding: 2px 4px;
			border-radius: 3px;
			font-size: 12.5px;
			margin-top: 7px;
			.check-center {
				float: right;
				width: 83%;
				border-bottom: 1px solid #ecedee;
				padding-bottom: 15px;
			}
		}
	}
	li:last-child .check-center {
		border: none !important;
	}
}
.product-grid .product-thumb .image + div {
    float: left;
    width: 100%;
}

.single_b_right {
    background: #ffffff;
    width: 100%;
    float: left;
    margin-top: 0;
    padding: 20px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.04);
    margin-bottom: 30px;
}

.cnt_outer_main {
	min-height: auto !important;
	padding-bottom: 0px;
}
.checkoutpagecontainer .KU_accordion .panel {
	border: 1px solid #eee;
	background: #fff;
	margin-bottom: 15px;
}
.checkoutpagecontainer .KU_accordion .panel .panel-body {
	border: none;
	padding-top: 0;
}
.checkoutpagecontainer .KU_accordion .panel .panel-heading {
	background-color: #fff;
	border: none;
	min-height: 60px;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .form-group.address {
	float: left;
	width: 65%;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .form-group.phone {
	float: left;
	width: 32%;
	margin-left: 3%;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .form-group #inputAddress {
	float: left;
}
.checkoutpagecontainer .KU_accordion .paymentaddress input {
	height: 46px;
	border: 1px solid #eee;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.checkoutpagecontainer .KU_accordion .paymentaddress textarea {
	min-height: 70px;
	width: 100%;
	border: 1px solid #eee;
	font-size: 13.5px;
    font-family: $font-family;
	padding: 10px 15px;
}
.checkoutpagecontainer .KU_accordion textarea::placeholder {
	@extend %ku_accordion;
}
 .checkoutpagecontainer .KU_accordion textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
	@extend %ku_accordion;
}
 .checkoutpagecontainer .KU_accordion textarea::-ms-input-placeholder { /* Microsoft Edge */
	@extend %ku_accordion;
}
.checkoutpagecontainer .KU_accordion input::placeholder {
	@extend %ku_accordion;
}
.checkoutpagecontainer .KU_accordion input:-ms-input-placeholder {
	@extend %ku_accordion;
}
.checkoutpagecontainer .KU_accordion input::-ms-input-placeholder {
	@extend %ku_accordion;
}
.checkoutpagecontainer .KU_accordion h4.panel-title, .checkoutpagecontainer .KU_accordion h4.panel-title a, .sp-payment .addcarddetail, .cst_product_checkbox.check_related h3 {
 	@extend %brandon_family_weight;
	color: $color;
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 1px;
	line-height: 24px;
	text-decoration: none;
}
span.KU_acco_title_icon.kuiconaddress {
	margin-right: 5px;
	display: inline-block;
	height: 20px;
	width: 20px;
	float: left;
}
span.KU_acco_title_icon.kuiconaddress:before {
	display: inline-block;
	background: url(../../image/add-icon.png) no-repeat 0 0;
	width: 15px;
	height: 15px;
	margin-right: 12px;
	content: "";
	position: absolute;
	top: 5px;
}
span.KU_acco_title_icon.kuicustinfo, span.KU_acco_title_icon.kuiconcustinfo {
	margin-right: 5px;
	display: inline-block;
	height: 20px;
	width: 20px;
	float: left;
}
span.KU_acco_title_icon.kuicustinfo:before, span.KU_acco_title_icon.kuiconcustinfo:before {
	display: inline-block;
	background: url(../../image/info-icon.png) no-repeat 0 0;
	width: 15px;
	height: 15px;
	margin-right: 12px;
	content: "";
	position: absolute;
	top: 5px;
}
span.gifticonbox {
	display: inline-block;
	background: url(../../image/gift-icon.png) no-repeat 0 0;
	width: 15px;
	height: 15px;
	margin-right: 5px;
}
.checkoutpagecontainer .KU_accordion .panel-heading {
	padding: 15px;
}
.checkoutpagecontainer .KU_accordion .panel-group .panel + .panel {
	margin-top: 15px;
}
.warningtext p {
 	font-family:$font-family;
	font-size: 12px;
	color: #000;
	padding-left: 15px;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .btn-group {
	width: 100%;
	border: 1px solid #e6e7e8;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .btn-group a.btn {
	width: 50%;
	float: left;
	background: #e6e7e8;
	box-shadow: none;
	color: $color;
	padding: 15px;
	font-size: 15px;
	@extend %brandon_family_weight;
	margin:$no-margin;
	border-radius: 0;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .btn-group a.btn i {
	font-size: 18px;
	margin-right: 10px;
}
.checkoutpagecontainer .KU_accordion .paymentaddress .btn-group a.btn.active {
	background: #f1f1f2;
}
.checkoutpagecontainer .KU_accordion .buttons {
	margin: $no-margin;
	padding: 20px 0;
}
.checkoutpagecontainer .KU_accordion .buttons input.btn {
	background: #43af49;
	color: #fff;
	text-transform: uppercase;
 	@extend %brandon_family_weight;
	font-size: 17px;
	letter-spacing: 1px;
	padding: 10px;
	width: 155px;
	height: 50px;
	border: none;
	transition: all 0.5s ease-in-out 0s;
}
.checkoutpagecontainer .KU_accordion .buttons input.btn:hover, .checkoutpagecontainer .KU_accordion #content_payment_address .buttons input.btn:focus {
	background: #B85C72;
}
.checkoutpagecontainer .KU_accordion .paymentaddress input:focus, .checkoutpagecontainer .KU_accordion .paymentaddress textarea:focus {
	border-color: #b85c72;
	outline: none;
}
.checkoutpagecontainer .KU_accordion {
	padding-right: 60px;
}
.checkoutleftsection {
    float: left;
    width: 100%;
    border-right: 1px solid #eee;
}
.checkoutright table tr td {
	border: none;
	padding: 10px 0;
	font-size: 16px;
	color: $color;
 	font-family:$font-family;
}
.checkoutright table tr td:first-child {
	text-align: left;
}
.checkoutright table {
	border: none;
	background: #fff;
}
.checkoutright .table-hover > tbody > tr:hover {
	border: none;
	background: #fff;
}
.checkoutright table tr:last-child, .checkoutright table tr:last-child:hover {
	border-top: 1px solid #ecedee;
}
.checkoutright table tr {
	padding-left: 20px;
}
.checkoutpagecontainer .KU_accordion .paymentaddress {
	padding: $no-padding;
}
.checkoutpagecontainer .KU_accordion .alert {
	padding: 5px 15px;
	margin-bottom: 10px;
}
.checkoutpagecontainer .KU_accordion h4.panel-title .leftsection {
	float: left;
}
.checkoutpagecontainer .KU_accordion h4.panel-title .rightsection {
	float: right;
}
.checkoutpagecontainer .KU_accordion h4.panel-title .rightsection .gifttextbox {
	margin-right: 5px;
	text-transform: initial;
}
.checkoutpagecontainer .KU_accordion h4.panel-title a#changeadrresslink, .checkoutpagecontainer .KU_accordion h4.panel-title a.changelink {
	float: right;
	text-transform: initial;
	color: #43af49;
}
.checkoutpagecontainer .KU_accordion h4.panel-title {
	margin-top: 4px;
	position:relative;
	width:100%;
	font-weight: normal;
}
.checkoutpagecontainer .KU_accordion h4.panel-title span { font-weight:bold;}
span.KU_acco_title_icon.kuicontime {
	margin-right: 5px;
	display: inline-block;
	height: 20px;
	width: 20px;
	float: left;
}
span.KU_acco_title_icon.kuicontime:before {
	display: inline-block;
	background: url(../../image/time-icon.png) no-repeat 0 0;
	width: 16px;
	height: 16px;
	margin-right: 12px;
	content: "";
	position: absolute;
	top:3px;
}
.delivery-date table.choose-datePC table.PC-heading-A th a {
 	font-family: $font-family;
	color: $color;
	font-size: 13px;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	text-decoration: none;
	padding-top: 15px;
	display: inline-block;
	width: 100%;
	border: 1px solid #eee;
	padding-bottom: 15px;
	text-align: center;
	border-top:none;
}
.delivery-date table.choose-datePC table.PC-heading-A th a.dayselected {
    border-bottom: none;
    color: #39b54a;
}
.delivery-date table.choose-datePC table.PC-heading-A th a:hover {
	color: #39b54a;
}
.checkoutpagecontainer .KU_accordion .alert .close:hover, .checkoutpagecontainer .KU_accordion .alert .close:focus {
    color: red;
}
.checkoutpagecontainer .KU_accordion .alert .close { color: red;}
table.PC-heading-A tbody {
	border: none !important;
}
.delivery-date table.choose-datePC table.PC-heading-A th {
	text-align: center;
	font-weight: normal;
}
.delivery-date table.choose-datePC tbody {
	border: 1px solid #eee;
}
.delivery-date table {
	width: 100%;
}
.delivery-date table tr td {
 	font-family: $font-family;
	color: $color;
	font-size: 13px;
	letter-spacing: 1px;
	text-transform: initial;
}
.delivery-date table tr td:last-child {
	color: #43af49;
 	@extend %brandon_family_weight;
	text-transform: uppercase;
}
.delivery-date table.PC-heading-A {
	margin-bottom: 7px;
}
.delivery-date table.PC-bodyA tbody {
	border-top: none !important;
}
.delivery-date table.PC-bodyA tr {
	border-bottom: 1px solid #eee;
}
.delivery-date table.PC-bodyA tr.rowtigger { cursor: pointer;}
.delivery-date table.PC-bodyA tr.rowtigger:hover {
    background: #eee;
}
tr.rowtigger.selectedrow td {
    color: #43af49;
}
.delivery-date table.PC-bodyA tr td {
	padding: 7px 0;
}
span.KU_acco_title_icon.kuiconpayment {
	margin-right: 5px;
	display: inline-block;
	height: 20px;
	width: 20px;
	float: left;
}
span.KU_acco_title_icon.kuiconpayment:before {
	display: inline-block;
	background: url(../../image/payement-icon.png) no-repeat 0 0;
	width: 19px;
	height: 16px;
	margin-right: 10px;
	content: "";
	position: absolute;
	top: 3px;
}
.panelpaymentouter ul {
	list-style: none;
	padding-left: 0;
}
.panelpaymentouter ul .radio label {
	color: $color;
 	@extend %brandon_family_weight;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 2px;
	padding-left:0;
}
.panelpaymentouter ul .radio label input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    float: left;
    margin-right: 6px;
    margin-left: 0;
    top: -2px;
    border-color: #39b54a;
}
.product_main_outer .product-grid .product-thumb:focus {
    outline: none;
}
.product_main_outer .product-grid:focus {
    outline: none;
}
.panelpaymentouter ul .radio label input:focus, .cart-preview .slick-initialized .slick-slide:focus { outline:none;}
label.activeoption span.paymenttype {
    content: '';
    position: absolute;
    left: 15px;
    top: 14px;
    width: 20px;
    height: 20px;
    border: 1px solid #43af49;
    border-radius: 100%;
    background: #fff;
}
label.activeoption span.paymenttype::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    border: 1px solid #43af49;
    border-radius: 100%;
    background: #39b54a;
}
.sp-payment .addcarddetail {
	cursor: pointer;
}
table.choose-datePC span.btn-choose {
	text-indent: -9999px;
	display: block;
	position: relative;
	margin-right: 18px;
	cursor: pointer;
	margin-left: 20px;
}
table.choose-datePC span.btn-choose:before {
	content: "";
	border: 1px solid #404040;
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	border-radius: 100%;
	top: 2px;
	background-color: #fff !important;
}
table.choose-datePC span.btn-choose.selectedaddr:after {
	background: #43af49;
	content: "";
	width: 12px;
	height: 12px;
	z-index: 9;
	position: absolute;
	left: 3px;
	top: 5px;
	border-radius: 100%;
}
table.choose-datePC span.timedisable {
    display: block;
    text-indent: 0px;
    position: relative;
    margin-left: 15px;
    font-size: 12px;
}
.delivery-date table.PC-bodyA tr.rowtigger.rowdisable {
    cursor: not-allowed !important;
}
.delivery-date table.PC-bodyA tr.rowtigger.rowdisable td {
    color: #c0bdc4;
}
.delivery-date table.PC-bodyA tr td:first-child {
    width: 25%;
}
/*table.choose-datePC span.timedisable:before {
	content: "x";
	color: red;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999999;
	font-size: 13px;
	display: block;
	width: 18px;
	height: 18px;
	font-family: sans-serif;
	text-indent: 0;
	border: 1px solid red;
	border-radius: 100%;
	padding: 0 5px;
	text-align: center;
	font-weight: $bold-weight;
	line-height: normal;
}*/
.form-group.orderfirstname {
	@extend %orderfirstname_lastname;
	margin-right: 2%;

}
.form-group.orderlastname {
	@extend %orderfirstname_lastname;
	margin-left: 2%;
}
#content_payment_address .termstext.pull-left {
	float: left;
	width: 60%;
}
#content_payment_address .termstext.pull-left p, #content_payment_address .termstext.pull-left p a {
 	font-family: $font-family;
	font-size: 14px;
	color: #000;
	padding-left: 0;
	font-weight: 500;
}
#content_payment_address .termstext.pull-left p a {
	text-decoration: underline;
}
.paywithnewcard {
    float: left;
    width: 100%;
}
.paywithnewcard .form-group {
    float: left;
    width: 100%;
}
.paywithnewcard .form-group input {
	height: 46px;
    border: 1px solid #eee;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.paywithnewcard .form-group input:focus {
border-color: #43af49;
	outline: none;
}
.paywithnewcard .form-group  col-sm-8 { padding:0;}
.paywithnewcard .form-group:first-child {
	float: left;
	width: 65%;
}
.paywithnewcard .form-group:nth-child(2n) {
	float: left;
	width: 32%;
	margin-left: 3%;
}
#collapse-checkout-payment-option .buttons { float:left;width:100%;}
span.deliveryaddress, span.ordercustomerinfo {
    display: inline-block;
}
.cst_product_checkbox.check_related {
    float: left;
    width: 100%;
	border: 1px solid #eee;
    background: #fff;
	    padding: 15px;
}
.cst_product_checkbox.check_related .form-group {
    float: left;
    width: 16.66%;
    margin-bottom: 0;
}
.checkout_review_outer {
    float: left;
    width: 100%;
    padding-right: 60px;
}
.cst_product_checkbox.check_related h3 { margin-top:0 ;margin-bottom:20px;}
.cst_product_checkbox.check_related .form-group .checkbox {margin-right:10px; width:62px;}
.cst_product_checkbox.check_related .form-group .checkbox img {border:1px solid #eee;}
.cst_product_checkbox.check_related .form-group .checkbox label {position:relative;}
.cst_product_checkbox.check_related .checkbox label:before {
   content: "+";
    font-size: 13px;
    color: #B85C72;
    position: absolute;
    visibility: visible;
    border: 1px solid #B85C72;
    border-radius: 100%;
    right: 4px;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 13px;
    top: 4px;
    cursor: pointer;
}
.cst_product_checkbox.check_related .checkbox label.labelbg:after {
    color: #fff;
    content: "";
    visibility: visible;
    display: block;
    z-index: 9999;
    position: absolute;
    width: 100%;
    background: rgba(69, 175, 73, 0.5);
    height: 100%;
    margin: auto;
    font-size: 20px;
    font-family: 'FontAwesome';
    padding: 16px 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	display:none;
}
.cst_product_checkbox.check_related .checkbox label.labelbg:before {
   content: "-";
    font-size: 13px;
    color: #43af49;
    position: absolute;
    visibility: visible;
    border: 1px solid #43af49;
    border-radius: 100%;
    right: 4px;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 13px;
    top: 4px;
    cursor: pointer;
}
.cst_product_checkbox.related_data .form-group.related_pro {
    float: left;
    width: 25%;
}
.cst_product_checkbox.related_data .form-group.related_pro img {border-radius:0;}
.order_summery_r {
    float: left;
    width: 100%;

	.totalsummyu {
		float: left;
		width: 100%;
		font-family: "Brandon Grotesque";
		font-size:16px;
		line-height:25px;
		padding-left: 20px;
    	padding-bottom: 2px;
		.totalsum_left {
			float: left;
			width: 50%;
		}
		.totalsum_right {
			float: right;
			width: 50%;
		}
	}
}
.order_summery_r .totalsummyu:last-child {
    border-top: 1px solid #dcdcdc;
    padding-top: 10px;
    margin-top: 10px;
}
.adddesign_copuon .totalsum_left, .adddesign_copuon .totalsum_right a {
    font-weight: 600;
    color: #43af49;
	text-decoration:none;
}
.order_summery_r .totalsummyu:last-child .totalsum_right, .order_summery_r .totalsummyu:last-child .totalsum_left {
    font-size: 21px;
    font-weight: 600;
}
.order_summery_r div#accordion .panel {
    box-shadow: none;
    border: none;
}
.order_summery_r div#accordion {
	margin-bottom:0;
}
.placeorder_outer .btnplaceorder {
    width: 100%;
    background: #43af49;
    border-color: #43af49;
    font-family: "Brandon Grotesque";
    text-transform: uppercase;
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
    padding: 14px;
    font-weight: 600;
}
.placeorder_outer .btnplaceorder:hover {
	@extend %prod_desc_right_btn_hover;
}
.hidepanel .panel-body {
    padding-left: 20px;
    padding-right: 0;
	font-family: "Brandon Grotesque";
}
.hidepanel .panel-body input#button-coupon {
    height: 34px;
    border: none;
    box-shadow: none;
    background: #43af49;
    border-color: #43af49;

}
.cart_section a.btn-primary {
    color: #fff;
    background-color: #111;
    border-color: #000;
}
.cart_section a.btn-primary:hover { background-color: #333;}
.cart_section .input-group.btn-block .input-group-btn .btn-primary {background-color: #333;border-color: #000;}
div#placeorderbtn {
    float: left;
    width: 100%;
}
.add_cart-box select.form-control {
    height: 48px;
    font-size: 16px;
	-webkit-appearance: none;
    -moz-appearance: none;
    background: transparent url("../../image/down-arrow-icon.png") no-repeat 80% center;
}
.add_cart-box select.form-control.openthis { background: transparent url("../../image/up-arrow-icon.png") no-repeat 80% center; }
.checkout-popup-box .add_cart-box select.form-control {
    background: transparent url("../../image/down-arrow-icon.png") no-repeat 95% center;
}
.checkout-popup-box .add_cart-box select.form-control.openthis { background: transparent url("../../image/up-arrow-icon.png") no-repeat 95% center; }
.btn--full-width {
    padding: 13px 25px;
    width: 100%;
    max-width: 100%;
    display: block;
    text-align: center;
    text-decoration: none;
}

.btn--full-width:hover {
    background: #46ae48;
    text-decoration: none;
}
.cart-preview__footer a.linkdisabled {
    background: #191919;
    color: #fff;
    cursor: not-allowed;
}
ul.newcatleftul li.active a {
    color: #474042;
}
a:hover, a:focus {
    color: #23527c;
    text-decoration: none;
}
