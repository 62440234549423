$position: center center;
$repeat: no-repeat;
$size: cover;
$default-width: 100%;
$default-float: left;
$hodgson_trading_color: #231f20;
$copyright_text_color: #939598;
$li_a_hover_color: #B85C72;
$footer_h5_font-size:18px;
%li_a_hover_color_h {
	color: $li_a_hover_color;
	text-decoration: none;
	outline: none;
}

.taste-outer img {width:100%;}
.desktop_screen {display:block;}
.mobile_screen {display:none;}
.taste-outer {
	background-repeat: $repeat;
    background-size: $size;
    background-position: $position;
	padding: 0;
	width:$default-width;
	float:$default-float;
	position:relative;
	margin-top:35px;
	max-width:$default-width;


	.tast-text-item {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 30px;
		text-align:center;
		h2 {
			font-family: 'Bodoni MT';
			font-size: 53px;
			padding: 20px 40px 0;
			margin: 0;
			color: #720e34;
		}
		h3 {
			font-size: 140px;
			font-family: 'Bodoni MT';
			padding: 0 0 0 160px;
			color: #720e34;
		}
		a {
			text-align: center;
			margin: 0 auto;
			background: #43b049;
			display: inline-block;
			padding: 8px 30px;
			font-weight:bold;
			font-size: $footer_h5_font-size;
			text-transform: uppercase;
			color: #fff;
			border-radius: 8px;
			transition: all 0.5s ease-in-out 0s;
			font-family: "Century Gothic";
			float:none;

		}
	}
	 .tast-text-item a:hover, .tast-text-item a:focus {
		background: $li_a_hover_color;
		@extend %li_a_hover_color_h;
		color: #FFF;
	}
}
.main-footer {
  margin: 35px 0 0;
	border-top: 1px solid rgba(255,255,255,0.12);
	box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1);
	padding-top: 45px;
	padding-bottom: 30px;
	float: left;
  width: 100%;
	background-color: #fff;

	> .container {
		max-width: 750px;
	}

	.information h5 {
		font-size: 13px;
		margin: 0 0 10px;
		font-family: 'SF Pro Text';
		font-weight: normal;
		color: #000;
		opacity: 0.3;
		letter-spacing: -0.19px;
		line-height: 33px;
	}

	.information ul {
		margin: 0;
		padding: 0;

		li {
			list-style-type: none;

			a {
				margin: 0 0 30px;
				padding: 0;
				font-family: 'SF Pro Text';
				font-size: 14px;
				line-height: 16px;
				display: block;
				color:#000;
				letter-spacing: -0.7px;
				font-weight: normal;
			}

			a:hover, a:focus {
				@extend %li_a_hover_color_h;
			}

		}
	}

	.social-icon {
		margin-top: 55px;
		text-align: center;

		h5 {
			font-size: $footer_h5_font-size;
			font-family: 'Renogare';
			color: #939598;
			text-transform: uppercase;
			margin-bottom:0;
		}

		.hodgson_trading {
			color: $hodgson_trading_color;
			font-family: 'Brandon Grotesque';
			margin-top: 30px;
			font-weight: normal;

			span {font-weight: bold;}
			img {
				margin-left: 10px;
			}
		}


		ul li {
			display: inline-block;
			border-radius: 0;
			width: 40px;
			height: 40px;
			text-align: center;
			font-size: 24px;
			margin: 0 20px;

			a {
				padding: 0 0;
				display: block;
			}

			&:hover a {
				color: #000;
			}
		}
	}

	.copyright-text {
		margin-top: 30px;
		text-align: center;
		font-family: 'AvenirRoman';
		font-size: 13px;
		color: #000;
		opacity: 0.5;
		letter-spacing: -0.18px;
		line-height: 24px;
		a {
			@extend %li_a_hover_color_h;
			color: #000;
		}
	}
}
.main-footer .information ul li a:last-child {
	white-space: nowrap;
}

// footer
#footer-outer {
	position: relative;
	width: 100vw;
	left: 50%;
	margin-left: -50vw;
}

.footer-head {
	margin: 0;
	margin-bottom: 35px;
	font-size: 32px;
	text-align: center;
	font-weight: 500;
	line-height: 38px;
	font-family: 'SF Pro Text';
	color: #000000;
	letter-spacing: -0.7px;
}

.newsletter-outer {
	text-align: center;

	> div {
		margin-bottom: 45px;
		max-width: 360px;
		width: 100%;
		vertical-align: top;
		display: inline-block;
	}

	.input-group {

		.form-control {
			max-width: 100%;
			width: 237px;
			border-radius: 5px;
			padding: 10px 20px;
			font-size: 14px;
			line-height: 22px;
			border: none;
			height: 42px;
			background-color: rgba(0, 0, 0, 0.06);
			box-shadow: none;
			font-family: 'SF Pro Text';
			font-weight: normal;
			color: #000;
		}

		.btn {
			height: 42px;
			color: #fff;
			max-width: 100%;
			width: 110px;
			border-radius: 5px;
			margin-left: 10px;
			background-color: #b85c72;
			box-shadow: none;
			font-family: 'SF Pro Text';
			font-weight: 500;
			font-size: 14px;
			letter-spacing: normal;
			padding: 0;
			display: block;;

			&:hover {
				background-color: #da7d93;
			}
		}
	}
}

.footer-logo {
	margin-top: 25px;
	text-align: center;

	.img-responsive {
		opacity: 0.25;
		display: inline-block;
	}
}


@media (max-width: 767px) {
	.main-footer {
		margin-top: 0;
		padding-top: 25px;
	}

	.information {
		text-align: center;
	}

	.footer-head {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 15px;
	}

	.newsletter-outer {

		.input-group {
			display: block;
		}
	}

	.newsletter-outer > div {
		margin-bottom: 15px;
	}

	.newsletter-outer .input-group .form-control {
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
	}

	.input-group-btn {
		width: 100%;
		display: inline-block;
	}

	.newsletter-outer .input-group .btn {
		width: 100%;
		margin-left: 0;
	}
}


@media (max-width: 480px) {
	.newsletter-outer > div {
		max-width: 280px;
	}
}
