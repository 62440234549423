[class^="col-"].shrink {
    padding: 0;
}

@media (min-width: 1024px) and (max-width: 1199px) {
    // home page
    // banner
    .owl-carousel .owl-item .item .cell-image {
        width: 30%;
    }

    .owl-carousel .owl-item .item .cell-heading {
        width: 70%;
    }

    .alcohol-text h1 {
        font-size: 36px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    // home page
    // banner
    .owl-carousel .owl-item .item .cell-image {
        width: 40%;
    }

    .owl-carousel .owl-item .item .cell-heading {
        width: 60%;
    }

    .owl-carousel .owl-item .item img {
        max-width: 120%;
    }

    .alcohol-text h1 {
        font-size: 26px;
    }

    // shoping-outer
    .shoping-outer .glass-img h2 {
        font-size: 20px;
    }

    .shoping-outer .glass-img p {
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    // ================================
    // home page
    // ================================
    // banner
    .owl-carousel .owl-item .item [class^="col-"] {
        padding: 0;
    }

    .owl-carousel .owl-item .item .cell-image {
        width: 100%;
        text-align: center;
        vertical-align: top;
    }

    .owl-carousel .owl-item .item .cell-heading {
        width: 100%;
        vertical-align: top;
    }

    .owl-carousel .owl-item .item img {
        max-width: 100%;
        display: inline-block;
    }

    .owl-item {
        height: auto;
    }

    .alcohol-text {
        text-align: center;
        padding-bottom: 30px;
    }

    .alcohol-text h1 {
        font-size: 14px;
        line-height: 1.45;
        letter-spacing: 0;
    }

    // shoping-outer
    .shoping-outer .col-xs-4 {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
    }

    .shrink {
        padding: 0;
    }

    // hot items
    .hot_items {
        padding: 0 25px 25px;
    }

    .hot_items .hot-item-text {
        padding: 20px 0 12px;
    }

    .product_main_outer .product-grid .product-thumb .caption h4 {
        text-align: center;
    }

    .product_main_outer .product-grid .product-thumb .caption p {
        text-align: center;
        width: 100%;
    }

    .product_main_outer .product-grid .product-thumb .caption p.price {
        text-align: center;
    }


    .dealofweekcolor.product_main_outer .product-grid .product-thumb .caption h4 {
        text-align: center;
    }

    .dealofweekcolor.product_main_outer .product-grid .product-thumb .caption p {
        text-align: right;
        width: auto;
    }

    .dealofweekcolor.product_main_outer .product-grid .product-thumb .caption p.price {
        text-align: left;
    }


    // ==========================
    // cart
    // ==========================
    .cart_section {

        .buttons {

            .pull-left {
                width: 100%;
                margin-bottom: 20px;
                text-align: center;
            }

            .pull-right {
                margin-bottom: 20px;
                width: 100%;
                text-align: center;
            }
        }
    }

    // add cart
    .single_page_right #product .add_cart-box {

        [class^="col-"] {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }

        select.form-control {
            margin-bottom: 15px;
            background-position: 95% 50%;
        }
    }
}
