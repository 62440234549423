%hot-item-hover {
	text-decoration:none;
	color: #da7d93;
}
.hot_items .hot-item-text:after {
	content: "";
	height: 1.5px;
	width: 100%;
	float: left;
	background: #dcddde;
	padding-bottom: 0;
	box-shadow: 0px 1px 0px 0.5px #eaeaea;
	margin-top:5px;
	display: none;
}
.hot_items {
	padding:20px 32px 27px;
	background-color: #fff;
	box-shadow: 0 2px 36px 0 rgba(0,0,0,0.04);
	margin-bottom: 40px;
	border-radius: 4px;

	.hot-item-text {
		margin-bottom:20px;
		padding: 0 5px;
		h2 {
			float: left;
			margin: 0;
			padding: 0;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 600;
			font-family: 'SF Pro Text';
			letter-spacing: 1px;
			color: #000000;
			letter-spacing: -0.27px;
			line-height: 21px;
		}
		a {
			float: right;
			color: #780D17;
			font-size: 16px;
			font-family: 'SF Pro Text';
			letter-spacing: -0.38px;
			line-height: 20px;
		}
		a:hover, a:focus {
			@extend %hot-item-hover;
		}
		a::after {
			content: "\f105";
			font-family: 'fontawesome';
			padding: 0;
			font-size: 25px;
			vertical-align: text-bottom;

		}
	}

	.slick-prev, .slick-next {
		transform: scale(0.8);
	}

	.slick-prev {

	}

	.slick-next {
		right: 12px;
	}

	.product {
		padding: 0;
		margin: 25px 10px 0 0;
		width: 180px;
	}
	.product:last-child {
		margin: 25px 0 0 0px;
	}
	.product img {
		border: 1px solid #dcddde;
		width: 100%;
	}
	.product-text {
		padding: 10px;
		border-bottom: 1px solid #e7e7e8;
		border-left: 1px solid #e7e7e8;
		border-right: 1px solid #e7e7e8;
		min-height: 95px;
	}
	.product-text h3 a {
		font-size: 16px;
		margin: 0;
		font-family: "Century Gothic";
		font-weight: bold;
		background: none;
		color: #000;
		text-align: left;
	}
	.product-text h3 {
		margin: 0;
		padding: 0;
	}
	.product-text h3 a:hover {
		background: none;
		color: #000;
	}
	.product-text p {
		font-family: 'Brandon Grotesque';
		font-size: 18px;
		padding: 0;
		margin: 0;
		text-transform:capitalize;
	}
	.product-text span {
		padding: 0 0;
		float: right;
		text-decoration: line-through;
		color: #939598;
	}
	.product-text strong {
		font-size: 16px;
		font-family: "Century Gothic";
		color: #e9194b;
	}
	.product .custombutton button {
		width: 100%;
		display: inline-block;
		text-align: center;
		background: #43b049;
		margin: 20px 0 0;
		padding: 12px 0;
		font-size: 18px;
		text-transform: uppercase;
		color: #fff;
		font-family: "Century Gothic";
		font-weight: bold;
		border-radius: 40px;
		border:none;
		letter-spacing:2px;
		transition: all 0.5s ease-in-out 0s;
	}
	.product .custombutton button:hover, .product .custombutton button:focus {
		background: #B85C72;
		color: #fff;
		text-decoration: none;
		outline: none;
	}
}

// hot items
.dealofweekcolor {
	background: #b85c72;

	.slick-prev::before {
	    content: url('../../image/arrow-left-white.png') !important;
	}
	.slick-next::before {
	    content: url('../../image/arrow-right-white.png') !important;
	}

	h2 {
		color: #fff !important;
	}
	.hot-item-text{
		a {
			color: #ffffff;

			&:hover {
				color: #f5d6dd;
			}
		}
	}
	.caption{
		.price{
			width: calc(100% - 95px);
			float: left;
			span {
				display: inline-block;
				font-weight: normal;
				color: #000;
				&:first-child{
					display: block;
					padding-bottom: 5px;
				}

			}
		}
		.prices {
			text-align: right;
			float: right;
			width: 95px;
			padding-left: 8px !important;
			padding-top: 8px !important;
			span {
				color: #B85C72 !important;
				font-size:13px;
				display: block;
				padding: 0 0 3px;
			}
		}
	}
}
